import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Button,
  Breadcrumb,
  Badge,
} from "react-bootstrap";

import OffcanvasHelp from "../../components/OffcanvasHelp";
import NotyfContext from "../../contexts/NotyfContext";
import BundleStats from "./BundleStats";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";
import useAuth from "../../hooks/useAuth";
import { apiConfig } from "../../config";
import { setRefreshData } from "../../redux/slices/refreshData";

import axios from "axios";
import moment from "moment";

import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

import { useNavigate } from "react-router-dom";

const BundlesList = (data) => {
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [signupsTableData, setSignupsTableData] = useState([]);
  const refreshData = useSelector((state) => state.refreshdata);

  const processSignupData = (signup, operators) => {
    const {
      id,
      contact_name,
      contact_email,
      operator_id,
      created,
      completed,
      assigned_to,
      status,
    } = signup;

    const operatorName = operators.find((op) => op.value === operator_id);

    return {
      id,
      contact_name,
      contact_email,
      operator_id,
      operator_name: operatorName?.label,
      created,
      completed,
      assigned_to,
      status,
    };
  };

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };

    axios
      .get(apiConfig.symbillApiUrl + "signups/", axiosConfig)
      .then((response) => {
        const responseData = response.data;

        if (responseData.length === 0) {
          setNoData(true);
        } else {
          const processedData = responseData.map((signup) =>
            processSignupData(signup, data.operators)
          );
          setSignupsTableData(processedData);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
        console.error(error);
      });
  }, [bearerToken, refreshData, data]);

  const statusColourMap = {
    Draft: "primary",
    Pending: "secondary",
    Complete: "success",
  };
  const signupsTableColumns = [
    {
      Header: "Contact Name",
      accessor: "contact_name",
    },
    {
      Header: "Contact Email",
      accessor: "contact_email",
    },
    {
      Header: "Building Operator",
      accessor: "operator_name",
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) => (
        <Badge bg={statusColourMap[e.value]}>{e.row.original.status}</Badge>
      ),
    },
    {
      Header: "Created",
      accessor: "created",
      Cell: (e) => moment(e.value).local().format("DD-MM-YYYY"),
    },
    {
      Header: "Completed",
      accessor: "completed",
      Cell: (e) =>
        e.value ? moment(e.value).local().format("DD-MM-YYYY") : "",
    },
  ];
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Customer Signup Forms</Card.Title>
      </Card.Header>
      <Card.Body className="py-0">
        {loading && (
          <>
            <LogoLoader className="d-block m-auto p-4" />
          </>
        )}
        {!loading && noData && <NotFound />}
        {!loading && !noData && (
          <ColumnFilteringTable
            columns={signupsTableColumns}
            data={signupsTableData}
          />
        )}
      </Card.Body>
    </Card>
  );
};

const SearchFilter = ({ data }) => {
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [buildingOperator, setBuildingOperator] = useState([]);
  const [building, setBuilding] = useState([]);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [user, setUser] = useState([]);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const refreshData = useSelector((state) => state.refreshdata);

  const fetchBuildings = () => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };

    axios
      .get(
        `${apiConfig.connectedApiUrl}buildings/?page_size=1000&building_operator=${buildingOperator.value}`,
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          const buildings = response.data.buildings.items.map((building) => ({
            value: building.id,
            label: building.building_name,
          }));
          setBuildingOptions(buildings);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchUsers = () => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "users", axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          const users = response.data.map((user) => ({
            value: user.email,
            label: user.name,
          }));
          setUserOptions(users);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(fetchBuildings, [bearerToken, buildingOperator]);
  useEffect(fetchUsers, [bearerToken]);

  const handleCreateSignupFormSubmit = (event) => {
    event.preventDefault();
    const createSignupFormBody = {
      contact_name: contactName,
      contact_email: contactEmail,
      operator_id: parseInt(buildingOperator.value),
      building_id: parseInt(building.value),
      assigned_to: user.value,
    };

    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .post(
        apiConfig.symbillApiUrl + "signups/",
        createSignupFormBody,
        axiosConfig
      )
      .then((response) => {
        if (response.status === 201) {
          notyf.open({
            type: "success",
            message: "Signup form created successfully",
            duration: 30000,
            ripple: true,
            dismissible: true,
            position: { x: "right", y: "top" },
          });
        }
      })
      .catch((error) => {
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration: 30000,
          ripple: true,
          dismissible: true,
          position: { x: "right", y: "top" },
        });
        console.error(error);
      });
    setContactName("");
    setContactEmail("");
    setBuildingOperator([]);
    setBuilding([]);
    setUser([]);
    setTimeout(() => {
      dispatch(setRefreshData(!refreshData));
    }, 2000);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Create New Signup Form</Card.Title>
      </Card.Header>
      <Card.Body className="pt-0">
        <Form onSubmit={handleCreateSignupFormSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Contact Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact Name"
              onChange={(e) => {
                setContactName(e.target.value);
              }}
              value={contactName}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Contact Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Contact Email"
              onChange={(e) => setContactEmail(e.target.value)}
              value={contactEmail}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Building Operator</Form.Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={data.operators}
              onChange={(e) => {
                setBuildingOperator(e);
                setBuilding([]);
              }}
              value={buildingOperator}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Building</Form.Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={buildingOptions}
              onChange={setBuilding}
              value={building}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Assigned To</Form.Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={userOptions}
              onChange={setUser}
              value={user}
              required
            />
          </Form.Group>
          <div className="d-grid">
            <Button variant="primary" type="submit">
              Create Signup Form
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

const fetchBuildingOperators = async (bearerToken) => {
  const axiosConfig = {
    headers: { Authorization: bearerToken },
  };
  try {
    const response = await axios.get(
      `${apiConfig.connectedApiUrl}operators/?page_size=1000`,
      axiosConfig
    );
    if (response.status === 200) {
      return response.data.operators.items.map((operator) => ({
        value: operator.id,
        label: operator.operator_name,
      }));
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

const BundleList = () => {
  const { userAccessToken } = useAuth();
  const [buildingOperatorsList, setBuildingOperatorsList] = useState([]);

  useEffect(() => {
    fetchBuildingOperators(`Bearer ${userAccessToken}`).then((operators) => {
      setBuildingOperatorsList(operators);
    });
  }, [userAccessToken]);

  const navigate = useNavigate();

  return (
    <IsAllowed to="view:bundles" fallback={<NotAuthorised />}>
      <Helmet title="Customer Signup Forms" />
      <Container fluid className="p-0">
        <Breadcrumb className="float-end mt-2">
          <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Customer Signup Forms</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">
          Customer Signup Forms
          <OffcanvasHelp
            id="bundles"
            name="Customer Signup Forms"
            scroll
            backdrop
          />
        </h1>
        <Row>
          <BundleStats />
        </Row>
        <Row>
          <Col xl="9">
            <BundlesList operators={buildingOperatorsList} />
          </Col>
          <Col xl="3">
            <SearchFilter operators={buildingOperatorsList} />
          </Col>
        </Row>
      </Container>
    </IsAllowed>
  );
};

export default BundleList;
