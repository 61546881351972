import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { apiConfig } from "../../config";
import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

import OffcanvasHelp from "../../components/OffcanvasHelp";
import GetSupport from "../../components/GetSupport";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";

import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Button,
  Breadcrumb,
} from "react-bootstrap";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { tableColumns } from "./ticketsData_API.js";
import statusOptions from "./helpers/ticketStatusOptions";

import TicketStats from "../dashboards/Default/TicketStats";

import useAuth from "../../hooks/useAuth";
import usePermissions from "../../hooks/usePermissions";

import { setSearchFilters } from "../../redux/slices/filters";

const TicketsList = (filters) => {
  const { userAccessToken, isAuthenticated, user } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [ticketsList, setTicketsList] = useState([]);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const selectedAccount = useSelector((state) => state.accountswitcher);
  const selectedSearchFilter = useSelector((state) => state.searchfilters);
  const refreshData = useSelector((state) => state.refreshdata);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  let defaultSearchType;
  const defaultSearchTypeOrg = usePermissions("view:credentials:org");
  const defaultSearchTypeLandlord = usePermissions("view:credentials:landlord");
  const defaultSearchTypeAll = usePermissions("view:credentials:all");
  if (defaultSearchTypeOrg[1]) {
    defaultSearchType = "accountnumber";
  } else if (defaultSearchTypeLandlord[1] || defaultSearchTypeAll[1]) {
    defaultSearchType = "operator";
  }
  let defaultSearchValue = null;
  if (defaultSearchTypeOrg[1]) {
    defaultSearchValue = selectedAccount.current_account;
  } else if (defaultSearchTypeLandlord[1] || defaultSearchTypeAll[1]) {
    defaultSearchValue = selectedLandlord.landlordid;
  }

  React.useEffect(() => {
    if (user && isAuthenticated && defaultSearchValue) {
      const axiosConfig = {
        headers: { Authorization: bearerToken },
      };

      let search_type = selectedSearchFilter.search_type || defaultSearchType;
      let search_value =
        selectedSearchFilter.search_value || defaultSearchValue;

      let axiosUrl =
        apiConfig.ticketsApiUrl +
        "tickets/list?" +
        search_type +
        "=" +
        search_value;
      axios
        .get(axiosUrl, axiosConfig)
        .then(function (response) {
          if (!response.data) {
            throw new Error("No data from backend");
          }
          setLoading(false);
          setNoData(false);
          setTicketsList(response?.data);
        })
        .catch(function (error) {
          setLoading(false);
          setNoData(true);
          console.log("tickets api error", error);
        });
    }
  }, [
    refreshData,
    bearerToken,
    selectedLandlord,
    defaultSearchType,
    defaultSearchValue,
    selectedSearchFilter,
    isAuthenticated,
    user,
  ]);

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Tickets List</Card.Title>
      </Card.Header>
      <Card.Body>
        {loading && (
          <>
            <LogoLoader className="d-block m-auto p-4" />
          </>
        )}
        {!loading && noData && <NotFound />}
        {!loading && !noData && (
          <ColumnFilteringTable columns={tableColumns} data={ticketsList} />
        )}
      </Card.Body>
    </Card>
  );
};

const SearchFilter = (filters) => {
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [customerOptions, setCustomerOptions] = useState([]);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.symbillApiUrl +
          "newsymbillapi/byCtidList/" +
          selectedLandlord.landlordid,
        axiosConfig
      )
      .then(function (response) {
        let customersList = [];
        response.data.forEach((customer) => {
          customersList.push({
            value: customer.accountNumber,
            label: customer.companyName,
          });
        });
        setCustomerOptions(customersList);
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(
        apiConfig.connectedApiUrl +
          "buildings?page_size=1000&order_by=building_name%20ASC&building_operator=" +
          selectedLandlord.landlordid,
        axiosConfig
      )
      .then(function (response) {
        let buildingsList = [];
        response.data.buildings.items.forEach((building) => {
          buildingsList.push({
            value: building.id,
            label: building.building_name,
          });
        });
        setBuildingOptions(buildingsList);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [bearerToken, selectedLandlord, filters]);

  const dispatch = useDispatch();

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Search &amp; Filter</Card.Title>
      </Card.Header>
      <Card.Body className="pt-0">
        <Form.Group className="mb-3">
          <Form.Label>Company Name</Form.Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={customerOptions}
            isClearable={true}
            onChange={(e) => {
              dispatch(
                setSearchFilters({
                  search_value: e ? e.value : "",
                  search_type: e ? "accountnumber" : null,
                })
              );
              setCurrentFilter(e ? (e.value ? "customer" : null) : null);
            }}
            isDisabled={
              currentFilter === "customer" || currentFilter === null
                ? false
                : true
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Building</Form.Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={buildingOptions}
            isClearable={true}
            onChange={(e) => {
              dispatch(
                setSearchFilters({
                  search_value: e ? e.value : "",
                  search_type: e ? "buildingid" : null,
                })
              );
              setCurrentFilter(e ? (e.value ? "building" : null) : null);
            }}
            isDisabled={
              currentFilter === "building" || currentFilter === null
                ? false
                : true
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Status</Form.Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={statusOptions}
            isClearable={true}
            onChange={(e) => {
              dispatch(
                setSearchFilters({
                  search_value: e ? e.value : "",
                  search_type: e ? "status" : null,
                })
              );
              setCurrentFilter(e ? (e.value ? "status" : null) : null);
            }}
            isDisabled={
              currentFilter === "status" || currentFilter === null
                ? false
                : true
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            label="Show Prolonged Tickets Only"
            type="checkbox"
            checked={filters?.filters?.prolonged}
            onChange={(e) => {
              dispatch(
                setSearchFilters({
                  search_value: e ? e.target.checked : false,
                  search_type: e.target.checked ? "prolonged" : null,
                })
              );
              setCurrentFilter(
                e ? (e.target.checked ? "prolonged" : null) : null
              );
            }}
            disabled={
              currentFilter === "prolonged" || currentFilter === null
                ? false
                : true
            }
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

const SupportTickets = () => {
  const selectedCustomer = useSelector((state) => state.searchfilters);
  let showSearchFilters = false;
  const hasLandlordPermissions = usePermissions("view:credentials:landlord")[1];
  const hasAllPermissions = usePermissions("view:credentials:all")[1];
  if (hasLandlordPermissions || hasAllPermissions) {
    showSearchFilters = true;
  }
  const navigate = useNavigate();
  return (
    <IsAllowed to="view:tickets" fallback={<NotAuthorised />}>
      <React.Fragment>
        <Helmet title="Support Tickets" />
        <Container fluid className="p-0">
          <Breadcrumb className="float-end mt-2">
            <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Support Tickets</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="h3 mb-3">
            Support Tickets
            <OffcanvasHelp
              id="supporttickets"
              name="Support Tickets"
              scroll
              backdrop
            />
          </h1>
          <div className="clearfix"></div>
          <IsAllowed to="create:tickets">
            <Link to="/tickets/new">
              <Button variant="primary" className="mt-n1 mb-3">
                <FontAwesomeIcon icon={faPlus} /> New Ticket
              </Button>
            </Link>
          </IsAllowed>
          <IsAllowed to="view:tickets:stats">
            <Row>
              <TicketStats />
            </Row>
          </IsAllowed>
          <Row>
            <Col xl="9">
              <TicketsList filters={selectedCustomer} />
            </Col>
            <Col xl="3">
              {showSearchFilters && <SearchFilter filters={selectedCustomer} />}
              <GetSupport type="support" />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </IsAllowed>
  );
};

export default SupportTickets;
