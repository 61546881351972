import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Select from "react-select";
import OffcanvasHelp from "../../components/OffcanvasHelp";
import { useParams } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
//import "react-international-phone/build/index.css";
import moment from "moment";
import NotFound from "../../components/NotFound";
import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Button,
  Breadcrumb,
  Badge,
  Form,
  InputGroup,
} from "react-bootstrap";

import NotyfContext from "../../contexts/NotyfContext";

import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../config";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const Bundle = (data) => {
  // If data.data.form_data is populated, then we are viewing an pending customer
  // All fields should be disabled and the form should be read-only
  // Displaying the pending customer information
  const [readOnly, setReadOnly] = useState(false);
  const [readOnlyData, setReadOnlyData] = useState({});
  React.useEffect(() => {
    if (data.data.form_data) {
      setReadOnly(true);
      setReadOnlyData(data.data.form_data);
    }
  }, [data.data.form_data]);

  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const axiosConfig = { headers: { Authorization: bearerToken } };
  const [billingAddressValue, setBillingAddressValue] = useState("");
  const contactTypeOptions = [
    { value: "signatory", label: "Authorised Signatory" },
    { value: "billing", label: "Billing" },
    { value: "technical", label: "Technical" },
    { value: "primary", label: "Primary" },
  ];

  const [installDate, setInstallDate] = useState("");
  function handleInstallDate(e) {
    setInstallDate(e.target.value);
  }
  const [companyNumber, setCompanyNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
  };
  const [companyDomain, setCompanyDomain] = useState("");
  const handleCompanyDomain = (e) => {
    setCompanyDomain(e.target.value);
  };
  const [companyResult, setCompanyResult] = useState(null);
  const handleCompanyNumber = (e) => {
    setCompanyNumber(e.target.value);
  };
  const getCompanyInfo = async (companyNumber) => {
    const response = await axios.get(
      `${apiConfig.symbillApiUrl}/signups/ch/${companyNumber}`,
      axiosConfig
    );
    if (response.status === 200) {
      console.log("companies house", response.data);
      setCompanyName(response.data.company_name);
      setCompanyResult(true);
    } else {
      setCompanyResult(false);
    }
  };

  const [companyContacts, setCompanyContacts] = useState([]);
  const handleAddContact = () => {
    if (
      contactFirstName === "" ||
      contactLastName === "" ||
      contactEmail === "" ||
      contactPhone === "" ||
      contactType === []
    ) {
      return;
    }
    setCompanyContacts([
      ...companyContacts,
      {
        name: contactFirstName + " " + contactLastName,
        contact_type: contactType.label,
        email: contactEmail,
        phone: contactPhone,
      },
    ]);
    setContactFirstName("");
    setContactLastName("");
    setContactEmail("");
    setContactPhone("");
    setContactType([]);
    console.log(companyContacts.length);
  };
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const handleContactEmail = (e) => {
    setContactEmail(e.target.value);
  };
  const [contactType, setContactType] = useState([]);
  /*const handleContactType = (e) => {
    setContactType(e.target.value);
  };*/
  const [contactFirstName, setContactFirstName] = useState("");
  const handleContactFirstName = (e) => {
    setContactFirstName(e.target.value);
  };
  const [contactLastName, setContactLastName] = useState("");
  const handleContactLastName = (e) => {
    setContactLastName(e.target.value);
  };
  const [ukLimitedCompany, setUkLimitedCompany] = useState("true");
  const handleUkLimitedCompany = (e) => {
    setUkLimitedCompany(e.target.value);
  };

  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("30000");
  const [ripple] = useState(true);
  const [dismissible] = useState(true);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateCustomerSubmit = (e) => {
    e.preventDefault();
    const createCustomerBody = {
      building_operator: data.data.operator_id,
      install_building: data.data.building_id,
      install_date: installDate,
      uk_limited_company: ukLimitedCompany,
      company_name: companyName,
      company_number: parseInt(companyNumber),
      company_domain: companyDomain,
      company_address: billingAddressValue,
      company_contacts: companyContacts,
      status: "Pending",
    };
    console.log(createCustomerBody);
    const axiosConfig = { headers: { Authorization: bearerToken } };
    axios
      .patch(
        `${apiConfig.symbillApiUrl}/signups/${data.data.id}`,
        createCustomerBody,
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          // Launch notyf based on the API response
          notyf.open({
            type,
            message: "Customer signup submitted successfully",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
          // Cleanup form state, return to list view
          setInstallDate("");
          setCompanyNumber("");
          setCompanyName("");
          setCompanyDomain("");
          setCompanyResult(null);
          setCompanyContacts([]);
          setContactFirstName("");
          setContactLastName("");
          setContactEmail("");
          setContactPhone("");
          setContactType([]);
          setUkLimitedCompany("true");
          setTimeout(() => {
            dispatch(navigate(`/signups/list`));
          }, 1000);
        } else {
          notyf.open({
            type: "warning",
            message: "Signup form could not be updated",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch((error) => {
        // handle error
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        console.log(error);
      });
  };
  console.log("building name", data.building);
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">
          #{data.data.id} - {data.data.contact_name} ({data.data.contact_email})
          {readOnly ? (
            <Badge className="float-end">
              Pending Upload: Editing Disabled
            </Badge>
          ) : (
            ""
          )}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleCreateCustomerSubmit}>
          <h4 className="mb-3">Service Installation Details</h4>
          <p>
            Please provide us with some information about your desired service
            installation.
          </p>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Installation Building
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                placeholder=""
                value={data.building}
                disabled
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Requested Installation Date
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="date"
                placeholder="Requested Installation Date"
                value={readOnly ? readOnlyData.install_date : installDate}
                onChange={handleInstallDate}
                disabled={readOnly}
              />
            </Col>
          </Form.Group>

          <h4 className="mb-3">Company Details</h4>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Are you a UK registered company?
            </Form.Label>
            <Col sm={8}>
              <InputGroup className="mb-3">
                <Form.Check
                  inline
                  label="UK Limited Company"
                  type="radio"
                  value="true"
                  name="ukcompany"
                  id="true"
                  checked={
                    readOnly
                      ? readOnlyData.uk_limited_company === "true"
                      : ukLimitedCompany === "true"
                  }
                  disabled={readOnly}
                  onChange={handleUkLimitedCompany}
                />
                <Form.Check
                  inline
                  label="Not a UK Limited Company"
                  type="radio"
                  value="false"
                  name="ukcompany"
                  id="false"
                  checked={
                    readOnly
                      ? readOnlyData.uk_limited_company === "false"
                      : ukLimitedCompany === "false"
                  }
                  disabled={readOnly}
                  onChange={handleUkLimitedCompany}
                />
              </InputGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Company Registration Number
            </Form.Label>
            <Col sm={8}>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  name="company_number"
                  placeholder={
                    readOnly ? readOnlyData.company_number : companyNumber
                  }
                  value={readOnly ? readOnlyData.company_number : companyNumber}
                  maxLength={8}
                  onChange={handleCompanyNumber}
                  className={
                    companyResult === null
                      ? ""
                      : companyResult === false
                      ? "border-danger"
                      : "border-success"
                  }
                  disabled={readOnly ? true : ukLimitedCompany === "false"}
                />
                <Button
                  variant="secondary"
                  id="button-addon2"
                  onClick={() => getCompanyInfo(companyNumber)}
                  disabled={readOnly ? true : ukLimitedCompany === "false"}
                >
                  Retrieve Company Details
                </Button>
              </InputGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Company Name
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="company_name"
                placeholder="Company Name"
                value={readOnly ? readOnlyData.company_name : companyName}
                disabled={readOnly}
                onChange={handleCompanyName}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Company Domain
              <br />
              <span className="text-sm">(e.g. example.com)</span>
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="company_domain"
                placeholder="Company Domain Name"
                value={readOnly ? readOnlyData.company_domain : companyDomain}
                disabled={readOnly}
                onChange={handleCompanyDomain}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Billing Address
            </Form.Label>
            <Col sm={8}>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyD9I__gvEX0ScDy654-RJ9SOlt2sqeJGb4"
                apiOptions={{ types: ["address"] }}
                selectProps={{
                  billingAddressValue,
                  onChange: setBillingAddressValue,
                  placeholder: readOnly
                    ? readOnlyData.company_address.label
                    : "Start typing your address...",
                  isClearable: true,
                  isDisabled: readOnly,
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: "uk",
                  },
                }}
              />
            </Col>
          </Form.Group>
          <h4 className="mb-3">Company Contacts</h4>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Contact Name
            </Form.Label>
            <Col sm={8}>
              <Row>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="contact_name"
                    placeholder="First Name"
                    onChange={handleContactFirstName}
                    value={contactFirstName}
                    disabled={readOnly}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="contact_name"
                    placeholder="Last Name"
                    onChange={handleContactLastName}
                    value={contactLastName}
                    disabled={readOnly}
                  />
                </Col>
              </Row>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Contact Email Address
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="contact_email"
                placeholder="Contact Email Address"
                onChange={handleContactEmail}
                value={contactEmail}
                disabled={readOnly}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Contact Telephone Number
            </Form.Label>
            <Col sm={8}>
              <PhoneInput
                defaultCountry="gb"
                value={contactPhone}
                onChange={(phone) => setContactPhone(phone)}
                inputStyle={{ width: "100%" }}
                disabled={readOnly}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className="text-sm-right">
              Contact Type
            </Form.Label>
            <Col sm={8}>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={contactTypeOptions}
                onChange={setContactType}
                value={contactType}
                isDisabled={readOnly}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ size: 8, offset: 4 }}>
              <Button
                variant="secondary"
                onClick={() => handleAddContact()}
                disabled={readOnly}
              >
                Add Contact
              </Button>
            </Col>
          </Form.Group>
          <Col sm={{ size: 8, offset: 4 }}>
            <h5 className="mx-2">Contacts List ({companyContacts.length}):</h5>
            <Row sm={{ cols: 2 }}>
              {readOnly
                ? readOnlyData.company_contacts.map((contact) => (
                    <Col sm={6} className="d-flex">
                      <Card className="card w-50 border flex-fill mx-1">
                        <Card.Header>
                          <Card.Title className="mb-2">
                            {contact.name}
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            {contact.contact_type}
                          </Card.Subtitle>
                          <Card.Body className="p-0">
                            <Table size="sm m-0">
                              <tbody>
                                <tr>
                                  <th>Email</th>
                                  <td>{contact.email}</td>
                                </tr>
                                <tr>
                                  <th>Phone</th>
                                  <td>{contact.phone}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card.Header>
                      </Card>
                    </Col>
                  ))
                : companyContacts.map((contact) => (
                    <Col sm={6} className="d-flex">
                      <Card className="card w-50 border flex-fill mx-1">
                        <Card.Header>
                          <Card.Title className="mb-2">
                            {contact.name}
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            {contact.contact_type}
                          </Card.Subtitle>
                          <Card.Body className="p-0">
                            <Table size="sm m-0">
                              <tbody>
                                <tr>
                                  <th>Email</th>
                                  <td>{contact.email}</td>
                                </tr>
                                <tr>
                                  <th>Phone</th>
                                  <td>{contact.phone}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card.Header>
                      </Card>
                    </Col>
                  ))}
            </Row>
          </Col>
          <hr className="my-5" />
          {readOnly ? (
            <>
              <h4 className="mb-3">Signup Form Submitted</h4>
              <p>
                This signup form has already been completed and submitted. It's
                status is currently <strong>{readOnlyData.status}</strong>. Once
                the customer's details have been provisioned in the Wavenet
                systems, the status will updated to "Completed" and you will be
                able to view the customer's account details in Gateway.
              </p>
              <Form.Group as={Row} className="mb-3">
                <Col sm={{ size: 12, offset: 10 }}>
                  <Button
                    variant="secondary"
                    onClick={() => navigate(`/signups/list`)}
                  >
                    Return To List
                  </Button>
                </Col>
              </Form.Group>
            </>
          ) : (
            <>
              <h4 className="mb-3">Submit & Create Customer</h4>
              <p>
                Once you are happy with the information you have provided,
                submit this form. By submitting this form you will initiate the
                creation of a customer account accross Wavenet systems.
              </p>
              <Form.Group as={Row} className="mb-3">
                <Col sm={{ size: 12, offset: 10 }}>
                  <Button variant="primary" type="submit">
                    Create Customer
                  </Button>
                </Col>
              </Form.Group>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

const BundleProspectDetails = (data) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Form Details</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table size="sm">
          <tbody>
            <tr>
              <th>Created Date</th>
              <td>
                {moment(data.data.created).local().format("Do MMMM YYYY")}
              </td>
            </tr>
            <tr>
              <th>Contact Name</th>
              <td>{data.data.contact_name}</td>
            </tr>
            <tr>
              <th>Contact Email</th>
              <td>{data.data.contact_email}</td>
            </tr>
            <tr>
              <th>Building Operator</th>
              <td>{data.operator}</td>
            </tr>
            <tr>
              <th>Assigned To</th>
              <td>{data.data.assigned_to}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const ViewBundle = () => {
  // Get signup form data
  const { userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  // Get Signups data
  const [signupFormData, setSignupFormData] = useState([]);
  const { signupid } = useParams();
  React.useEffect(() => {
    const axiosConfig = { headers: { Authorization: bearerToken } };
    if (isAuthenticated) {
      axios
        .get(`${apiConfig.symbillApiUrl}signups/` + signupid, axiosConfig)
        .then((response) => {
          setSignupFormData(response.data.pop());
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setNoData(true);
          setLoading(false);
        });
    }
  }, [bearerToken, signupid, isAuthenticated]);

  // Get Buildings data to populate building name

  const [building, setBuilding] = useState([]);
  const [buildingOperator, setBuildingOperator] = useState([]);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.connectedApiUrl +
          "buildings/?page_size=1000&building_operator=" +
          parseInt(signupFormData.operator_id),
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("install building", signupFormData.building_id);
          let buildingName = response.data.buildings.items.filter(
            (building) => {
              return building.id === signupFormData.building_id;
            }
          );
          setBuilding(buildingName[0].building_name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [bearerToken, buildingOperator, signupFormData]);

  // Get Building Operator data to populate building operator
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.connectedApiUrl + "operators/?page_size=1000", axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          let operatorName = response.data.operators.items.filter(
            (operator) => {
              return operator.id === signupFormData.operator_id;
            }
          );
          setBuildingOperator(operatorName[0].operator_name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [bearerToken, signupFormData]);

  const navigate = useNavigate();

  return (
    <IsAllowed to="view:bundles" fallback={<NotAuthorised />}>
      <React.Fragment>
        <Helmet title="Customer Bundles" />
        <Container fluid className="p-0">
          <Breadcrumb className="float-end mt-2">
            <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate("/signups/list")}>
              Customer Signup Forms
            </Breadcrumb.Item>
            <Breadcrumb.Item active>#{signupFormData["id"]}</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="h3 mb-3">
            Customer Signup Forms
            <OffcanvasHelp
              id="viewingbundle"
              name="Viewing Signups"
              scroll
              backdrop
            />
          </h1>
          <div className="clearfix"></div>
          <Row>
            <Col xl="8">
              {loading && (
                <>
                  <LogoLoader className="d-block m-auto p-4" />
                </>
              )}
              {!loading && noData && <NotFound />}
              {!loading && !noData && (
                <Bundle data={signupFormData} building={building} />
              )}
            </Col>
            <Col xl="4">
              {loading && (
                <>
                  <LogoLoader className="d-block m-auto p-4" />
                </>
              )}
              {!loading && noData && <NotFound />}
              {!loading && !noData && (
                <BundleProspectDetails
                  data={signupFormData}
                  operator={buildingOperator}
                />
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </IsAllowed>
  );
};

export default ViewBundle;
