import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Container,
  // Card,
  Row,
  Col,
  InputGroup,
  Button,
  Badge,
  // Table,
} from "react-bootstrap";
import {
  Bell,
  Inbox,
  UserPlus,
  Calendar,
  CheckCircle,
  XCircle,
  Activity,
} from "react-feather";

import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../config";

import { ReactComponent as Loader } from "../../assets/img/wavenet-animated-loader3.svg";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment-timezone";
import Select from "react-select";
import DOMPurify from "dompurify";

const ViewNotifications = () => {
  const navigate = useNavigate();
  // Retrieve notifications from API
  const { user, userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [notificationsList, setNotificationsList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [filteredNotificationsList, setFilteredNotificationsList] = useState(
    []
  );
  const [reload, setReload] = useState(false);
  // Display a loading state while waiting for the API response
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (user && isAuthenticated) {
      axios
        .get(apiConfig.usersApiUrl + "notifications/", axiosConfig)
        .then((response) => {
          const groupedData = groupNotificationsByDate(
            response.data.notifications
          );
          setNotificationsList(groupedData);
          setFilteredNotificationsList(groupedData);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Notifications list error", err);
        });
    }
  }, [user, bearerToken, isAuthenticated, reload]);

  const applyDateFilter = () => {
    const filteredData = Object.keys(notificationsList).reduce(
      (filtered, day) => {
        const notificationsInDay = notificationsList[day];
        let dayDate, compareGranularity;

        if (day === "Today") {
          dayDate = moment().startOf("day");
          compareGranularity = "day";
        } else if (day === "Yesterday") {
          dayDate = moment().subtract(1, "day").startOf("day");
          compareGranularity = "day";
        } else {
          dayDate = moment(day, "MMMM YYYY");
          compareGranularity = "month";
        }

        // Check if the dayDate is within the selected date range
        if (
          (!startDate ||
            dayDate.isSameOrAfter(startDate, compareGranularity)) &&
          (!endDate || dayDate.isSameOrBefore(endDate, compareGranularity))
        ) {
          // Check if the selectedType filter should be applied
          if (selectedType) {
            // Filter notifications by type
            const filteredNotifications = notificationsInDay.filter(
              (notification) =>
                notification.type.startsWith(selectedType.value + ".")
            );
            if (filteredNotifications.length > 0) {
              filtered[day] = filteredNotifications;
            }
          } else {
            // If no type is selected, include all notifications for the day/month/year
            filtered[day] = notificationsInDay;
          }
        }

        return filtered;
      },
      {}
    );

    setFilteredNotificationsList(filteredData);
  };

  const clearDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedType(null);
    setFilteredNotificationsList(notificationsList);
  };

  // Convert notifications to expected format (see temp_data.js)
  const groupNotificationsByDate = (notificationsList) => {
    const groupedData = {};

    notificationsList.forEach((notification) => {
      // const timeWithoutZone = notification.time.replace("Z", "");
      const notificationDate = moment(notification.time);
      console.log(notificationDate);
      const today = moment().startOf("day");
      const yesterday = moment().subtract(1, "day").startOf("day");

      let groupKey = "";

      if (notificationDate.isSame(today, "day")) {
        groupKey = "Today";
      } else if (notificationDate.isSame(yesterday, "day")) {
        groupKey = "Yesterday";
      } else {
        groupKey = notificationDate.format("MMMM YYYY");
      }

      if (!groupedData[groupKey]) {
        groupedData[groupKey] = [];
      }

      let deliveryMethods = [];
      switch (notification.deliveryMethod) {
        case "*":
          deliveryMethods = ["In-App", "SMS", "Email"];
          break;
        case "app":
          deliveryMethods = ["In-App"];
          break;
        case "sms":
          deliveryMethods = ["SMS", "In-App"];
          break;
        case "email":
          deliveryMethods = ["Email", "In-App"];
          break;
        default:
          deliveryMethods = ["In-App"];
      }

      groupedData[groupKey].push({
        title: notification.title,
        type: notification.type,
        date: notificationDate.format("D MMM, YYYY, h:mm a"),
        text: notification.description,
        html: notification.html,
        unread: notification.unread,
        link: notification.link,
        id: notification.id,
        deliveryMethods: deliveryMethods,
      });
    });

    return groupedData;
  };

  const markAsRead = (notification_id) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .patch(
        apiConfig.usersApiUrl + "notifications/" + notification_id + "/read",
        null,
        axiosConfig
      )
      .then((response) => {
        setReload(!reload);
      });
  };

  // Display notifications in a timeline format

  // React Select Styles
  const customStyles = {
    container: (base) => ({
      ...base,
      minWidth: 200,
    }),
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      borderRadius: 0,
      paddingLeft: 5,
      paddingRight: 5,
      whiteSpace: "nowrap",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: 2,
      margin: 0,
      marginTop: -4,
      paddingRight: 10,
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: 1,
    }),
    clearIndicator: (base) => ({
      ...base,
      marginTop: -4,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      marginTop: -3,
    }),
  };

  return (
    <React.Fragment>
      <Helmet title="Notifications" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Notifications Timeline</h1>

        {loading && (
          <>
            <Loader className="d-block m-auto p-4" />
          </>
        )}
        {!loading && (
          <>
            <div className="mb-3">
              <InputGroup className="mb-5 d-flex align-items-center justify-content-center">
                <InputGroup.Text>
                  <Bell
                    size={14}
                    className="align-middle text-secondary text-muted ms-n1 me-2"
                  />
                  Notification Type:{" "}
                </InputGroup.Text>
                <Select
                  isClearable={true}
                  value={selectedType}
                  onChange={(selectedOption) => setSelectedType(selectedOption)}
                  options={[
                    { value: "users", label: "Users" },
                    { value: "tickets", label: "Tickets" },
                    { value: "network", label: "Network" },
                  ]}
                  className="react-select"
                  classNamePrefix="react-select"
                  placeholder="All"
                  styles={customStyles}
                ></Select>
                <InputGroup.Text>
                  <Calendar
                    size={14}
                    className="align-middle text-secondary text-muted ms-n1 me-2"
                  />
                  From:{" "}
                </InputGroup.Text>
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  className={"form-control"}
                  placeholderText="All Time"
                />
                <InputGroup.Text>
                  <Calendar
                    size={14}
                    className="align-middle text-secondary text-muted ms-n1 me-2"
                  />
                  To:{" "}
                </InputGroup.Text>
                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => {
                    const endOfMonth = moment(date).endOf("month");
                    setEndDate(endOfMonth.toDate());
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  className={"form-control"}
                  placeholderText="All Time"
                />
                <Button
                  variant="secondary"
                  id="button-addon2"
                  onClick={applyDateFilter}
                >
                  Apply Filter
                </Button>
                <Button
                  variant="light"
                  id="button-addon2"
                  onClick={clearDateFilter}
                >
                  Clear Filter
                </Button>
              </InputGroup>
            </div>
            <Row className="justify-content-center">
              <Col>
                {!Object.keys(filteredNotificationsList).length > 0 && (
                  <div className="text-center">
                    <h4 className="text-muted">
                      No notifications matching filters
                    </h4>
                  </div>
                )}
                <div className="timeline">
                  {Object.keys(filteredNotificationsList).map((day, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <div className="timeline-show mb-3 text-center">
                          <h5 className="m-0 time-show-name">{day}</h5>
                        </div>

                        {(filteredNotificationsList[day] || []).map(
                          (item, idx) => {
                            let icon = (
                              <Bell
                                size={20}
                                className="align-middle text-secondary text-muted p-1"
                              />
                            );

                            if (item.type.startsWith("network")) {
                              icon = (
                                <Activity
                                  size={20}
                                  className="align-middle text-secondary text-muted p-1"
                                />
                              );
                            }

                            if (item.type.startsWith("tickets")) {
                              icon = (
                                <Inbox
                                  size={20}
                                  className="align-middle text-secondary text-muted p-1"
                                />
                              );
                            }

                            if (item.type.startsWith("users")) {
                              icon = (
                                <UserPlus
                                  size={14}
                                  className="align-middle text-secondary text-muted"
                                />
                              );
                            }
                            return (
                              <div
                                key={idx}
                                className={`timeline-lg-item ${
                                  idx % 2 === 0 ? "timeline-item-left" : ""
                                }`}
                                id={`notification-${item.id}`}
                              >
                                <div className="timeline-desk">
                                  <div className="timeline-box">
                                    <span className="arrow-alt"></span>
                                    <span className="timeline-icon">
                                      {icon}
                                    </span>
                                    <Row>
                                      <Col className="col-xl-8">
                                        <h4 className="mt-0 mb-1 font-16">
                                          <Badge
                                            bg={""}
                                            className={
                                              (item.unread === 1
                                                ? "badge-soft-primary"
                                                : "badge-soft-info") +
                                              " p-1 me-2"
                                            }
                                          >
                                            {item.unread === 1
                                              ? "Unread"
                                              : "Read"}
                                          </Badge>
                                          {item.title}
                                        </h4>
                                        <p className="text-muted">
                                          <small>{item.date}</small>
                                        </p>
                                      </Col>
                                      <Col className="col-xl-4">
                                        <DeliveryMethodBadges
                                          deliveryMethods={item.deliveryMethods}
                                        />
                                      </Col>
                                    </Row>

                                    <hr />
                                    <p>{item.text}</p>
                                    {item.html && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(item.html),
                                        }}
                                        className="notification-html"
                                      ></div>
                                    )}
                                    {/* <Card
                                      style={{
                                        borderRadius: "10px",
                                        border: "1px solid #e5e5e5",
                                      }}
                                    >
                                      <Card.Body>
                                        <Card.Title className="fs-3 text-dark">
                                          {item.title}
                                          <Badge
                                            bg={""}
                                            className="bage badge-soft-danger p-1 me-2 float-end"
                                          >
                                            Active
                                          </Badge>
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 fs-4">
                                          A device is not responding to ping
                                        </Card.Subtitle>
                                        <Card.Text>
                                          <Table striped bordered>
                                            <tbody>
                                              <tr>
                                                <td>Device Name</td>
                                                <td>
                                                  RecordHall-2ndFlr-PAccS2
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Device Location</td>
                                                <td>Record Hall</td>
                                              </tr>
                                              <tr>
                                                <td>Device Details</td>
                                                <td>Juniper SRX345</td>
                                              </tr>
                                              <tr>
                                                <td>Alert Severity</td>
                                                <td>Critical</td>
                                              </tr>
                                              <tr>
                                                <td>Alert Description</td>
                                                <td>
                                                  The device is not responding
                                                  to ping
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Alert Time</td>
                                                <td>21:24:40 09/04/2024</td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </Card.Text>
                                      </Card.Body>
                                    </Card> */}
                                    {item.link && (
                                      <Button
                                        onClick={() => navigate(item.link)}
                                        className="btn-secondary me-2"
                                      >
                                        View Details
                                      </Button>
                                    )}
                                    <Button
                                      onClick={() => markAsRead(item.id)}
                                      className="btn-light"
                                      disabled={item.unread === 0}
                                    >
                                      Mark As Read
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </React.Fragment>
  );
};

const DeliveryMethodBadges = ({ deliveryMethods }) => {
  const allMethods = ["Email", "SMS", "In-App"];

  return (
    <>
      {allMethods.map((method) => (
        <Badge
          key={method}
          bg=""
          className={`badge-soft-${
            deliveryMethods.includes(method) ? "success" : "secondary"
          } p-1 me-1 mb-1 float-end`}
        >
          {deliveryMethods.includes(method) ? (
            <>
              <CheckCircle size={8} className="align-middle me-1" />
              {method}
            </>
          ) : (
            <>
              <XCircle size={8} className="align-middle me-1" />
              {method}
            </>
          )}
        </Badge>
      ))}
    </>
  );
};

/* -- [Old] Conditional code for timeline items --

                                  {item.images && (
                                    <div className="timeline-album mb-3 d-flex gap-1">
                                      {(item.images || []).map((img, idx) => {
                                        return (
                                          <span
                                            key={idx}
                                            className="cursor-pointer"
                                          >
                                            <img alt="" src={img} />
                                          </span>
                                        );
                                      })}
                                    </div>
                                  )}

                                  {item.reactions &&
                                    (item.reactions || []).map((item, idx) => {
                                      return (
                                        <button
                                          key={idx}
                                          className="btn btn-sm btn-light me-1"
                                        >
                                          <span
                                            role="img"
                                            aria-label="thumbs-up"
                                          >
                                            {item.emoji}
                                          </span>
                                          {item.count}
                                        </button>
                                      );
                                    })}

                                  {item.user && (
                                    <div className="d-flex">
                                      <img
                                        src={item.user.image}
                                        alt="Arya S"
                                        className="rounded-circle me-2"
                                        height="24"
                                      />
                                      <div>
                                        <h5 className="mt-1 font-14 mb-0">
                                          {item.user.name}
                                          <small>- {item.user.position}</small>
                                        </h5>
                                      </div>
                                    </div>
                                  )}

*/

export default ViewNotifications;
