/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import { Badge, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IsAllowed from "../IsAllowed";
import Loader from "../Loader";
import IsDisabledForLandlordId from "../IsDisabledForLandlordId";

const CustomRouterLink = forwardRef((props, ref) => (
  <React.Fragment ref={ref}>
    <NavLink {...props} />
  </React.Fragment>
));

const SidebarNavListItem = (props) => {
  const {
    title,
    permissionName,
    href,
    depth = 0,
    children,
    icon,
    badge,
    open: openProp = false,
    disabledForLandlordIds,
    enabledForLandlordIds,
  } = props;

  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <IsAllowed to={"view:" + permissionName}>
        <IsDisabledForLandlordId
          disabledForLandlordIds={disabledForLandlordIds}
          enabledForLandlordIds={enabledForLandlordIds}
        >
          <li className={`sidebar-item ${open ? "active" : ""}`}>
            <a
              className={`sidebar-link ${open ? "" : "collapsed"}`}
              data-bs-toggle="collapse"
              aria-expanded={open ? "true" : "false"}
              depth={depth}
              onClick={handleToggle}
            >
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  size="lg"
                  className="text-center me-2"
                  style={{ width: "18" }}
                />
              )}{" "}
              <span className="align-middle" depth={depth}>
                {title}
              </span>
              {badge && (
                <Badge className="badge-sidebar-primary" bg="" size={18}>
                  {badge}
                </Badge>
              )}
              {open ? <div /> : <div />}
            </a>
            <Collapse in={open}>
              <ul className="sidebar-dropdown list-unstyled">{children}</ul>
            </Collapse>
          </li>
        </IsDisabledForLandlordId>
      </IsAllowed>
    );
  }

  return (
    <IsAllowed
      to={"view:" + permissionName}
      fallback=""
      loadingComponent={<Loader />}
    >
      <IsDisabledForLandlordId
        disabledForLandlordIds={disabledForLandlordIds}
        enabledForLandlordIds={enabledForLandlordIds}
      >
        <li className="sidebar-item">
          <CustomRouterLink
            depth={depth}
            to={href}
            activeclassname="active"
            className="sidebar-link"
          >
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                size="lg"
                className="text-center me-3"
                style={{ width: "18" }}
              />
            )}{" "}
            <span className="align-middle" depth={depth}>
              {title}
            </span>
            {badge && (
              <Badge className="badge-sidebar-primary" bg="" size={18}>
                {badge}
              </Badge>
            )}
          </CustomRouterLink>
        </li>
      </IsDisabledForLandlordId>
    </IsAllowed>
  );
};

export default SidebarNavListItem;
